var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('PageHeader',{attrs:{"items":_vm.items}}),_c('validation-observer',{ref:"simpleRules"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Level Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"input-label form-label"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Level Name"))+" ")]),_c('multiselect',{key:_vm.form.levelName,attrs:{"options":[
                  'warning level 1',
                  'warning level 2',
                  'warning level 3' ],"multiple":false},model:{value:(_vm.form.levelName),callback:function ($$v) {_vm.$set(_vm.form, "levelName", $$v)},expression:"form.levelName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('TextInput',{attrs:{"label":_vm.$t('Name'),"required":true},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Document Template","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('MultiSelectInput',{key:_vm.form.documentTemplateId,attrs:{"required":true,"options":_vm.documentServices.data,"multiple":false,"textLabel":_vm.$t('Document Template'),"label":"name","trackBy":"id","moduleName":"documentService"},model:{value:(_vm.form.documentTemplateId),callback:function ($$v) {_vm.$set(_vm.form, "documentTemplateId", $$v)},expression:"form.documentTemplateId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Period Days","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('text-input',{attrs:{"type":"number","required":true,"error":errors.periodDays,"label":_vm.$t('Period Days')},model:{value:(_vm.form.periodDays),callback:function ($$v) {_vm.$set(_vm.form, "periodDays", $$v)},expression:"form.periodDays"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Reminder Fee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('text-input',{attrs:{"type":"number","required":true,"error":errors.reminderFee,"label":_vm.$t('Reminder Fee')},model:{value:(_vm.form.reminderFee),callback:function ($$v) {_vm.$set(_vm.form, "reminderFee", $$v)},expression:"form.reminderFee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"Start Text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"input-label form-label"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Start Text"))+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.startText),expression:"form.startText"}],staticClass:"form-control",attrs:{"rows":"5"},domProps:{"value":(_vm.form.startText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "startText", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"End Text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"input-label form-label"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("End Text"))+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.endText),expression:"form.endText"}],staticClass:"form-control",attrs:{"rows":"5"},domProps:{"value":(_vm.form.endText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "endText", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)]),_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"Mail Text","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('label',{staticClass:"input-label form-label"},[_c('span',{staticStyle:{"color":"red"}},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Mail Text"))+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.mailText),expression:"form.mailText"}],staticClass:"form-control",attrs:{"rows":"5"},domProps:{"value":(_vm.form.mailText)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "mailText", $event.target.value)}}}),_vm._v(" "),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{staticClass:"d-flex align-items-center gap-1",attrs:{"variant":"primary"},on:{"click":_vm.updateReminderLevel}},[_c('feather-icon',{attrs:{"icon":"PlusIcon","size":"12"}}),_c('span',[_vm._v(_vm._s(_vm.$t("Update Reminder Level")))])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }